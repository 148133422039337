<template>
    <div class="no-scroll">
        <TopNavigator ref="top"/>
        <div class="line"></div>
        <div class="center" style="flex:1; margin:49rem auto 50rem auto; width:1200rem;">
            <div class="sign-in-content" style="height:570rem">
                <div class="left">
                <div class="title">Reset Your Password</div>
                <div class="input" style="margin-top:28rem">
                    <input class="input-area" type="text"  placeholder="Enter email address" v-model="regesterForm.Phone" maxlength="50" />
                </div>
                <div style="margin-top:15rem;display:flex">
                    <div class="input" style="width:272rem">
                    <input class="input-area" type="text"  placeholder="Verification Code" v-model="regesterForm.Captcha" maxlength="8" />
                    </div>
                    <div :class="`btn-verify no-select ${btnCaptchaText!='Verify'?'disabled':''}`" @click="GetCaptcha">{{btnCaptchaText}}</div>
                </div>

                <div class="input" style="margin-top:15rem;display:flex">
                    <!-- v-if="" -->
                    <input class="input-area" type="password" placeholder="Create New Password" v-model="regesterForm.Pwd" maxlength="50" />
                </div>
                <div class="input"  style="margin-top:15rem;display:flex">
                    <!-- v-if="" -->
                    <input class="input-area" type="password" placeholder="Confirm Your Password" v-model="repeatPassword" maxlength="50" />
                </div>
                <div class="btn-confirm no-select" style="margin-top:17rem" @click="submit">Submit</div>
                </div>
                <div class="right"></div>
            </div>
        </div>
        <BottomNavigator />
    </div>
</template>

<script>
import helper from "@/utils/helper";
import { Login, GetCaptcha, Register, OnWebWxLogin,ResetPassword } from "@/api/userApi";
import TopNavigator from "@/components/TopNavigator";
import BottomNavigator from "@/components/BottomNavigator";

export default {
    name:'ResetPwd',
    components: {
        TopNavigator,
        BottomNavigator,
    },
    data(){
        return {
            regesterForm: {
                Phone: "",
                Pwd: "",
                Captcha: "",
            },
            repeatPassword:'',
            btnCaptchaText: "Verify",
        }
    },
    mounted(){

    },
    methods:{
        GetCaptcha() {
            if (this.btnCaptchaText !== "Verify") {
                return;
            }
            if (this.lock) {
                this.$toasted.show("请稍后操作", {
                type: "error",
                });
                return;
            }
            this.lock = true;
            GetCaptcha(this.regesterForm).then((res) => {
                this.$toasted.show('A verification code has been send to your email address.');
                this.sec = 60;
                this.btnCaptchaText = `Again(${this.sec--})`;
                const timer = setInterval(() => {
                    this.btnCaptchaText = `Again(${this.sec--})`;
                    if (this.sec <= 0) {
                    clearInterval(timer);
                    this.btnCaptchaText = "Verify";
                    }
                }, 1000);
                this.isExist = res.data.isExist
                this.lock = false;
                })
                .catch((ex) => {
                this.lock = false;
                this.$toasted.show(ex, {
                    type: "error",
                });
                });
        },
        submit(){
            // this.$toasted.show("Reset password success!", {type: "success", onComplete: ()=>{
            //     this.$router.push('Sign?type=1');
            // }});                

            if(this.regesterForm.Pwd != this.repeatPassword)
            {
                this.$toasted.show("Two passwords do not match", {
                    type: "error",
                });
                return;
            }
            let resetForm = {
                phone: this.regesterForm.Phone,
                newPwd: this.regesterForm.Pwd,
                captcha: this.regesterForm.Captcha,
            };
            ResetPassword(resetForm).then((res)=>{
                this.$toasted.show("Reset password success!", {type: "success",});
                //this.OnTypeChanged(1);
                this.$router.push('Sign?type=1')
            }).catch(ex=>{
                this.$toasted.show(ex, {
                    type: "error",
                });
            });
        }
    }
}
</script>

<style scoped lang="scss">
.sign-in-content {
    width: 1200rem;
    height: 570rem;
    display: flex;
    border-radius: 2rem;
    background: #252525;
    .left {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            margin-top: 47rem;
            font-family: "GenSenMaruGothicTW-Bold";
            font-size: 28rem;
            line-height: 45rem;
            font-weight: 700;
            color: #fff;
        }
    }

    .right {
        width: 50%;
        height: 100%;
        border-radius: 0 2rem 2rem 0;
        background: url("../../assets/注册登陆-背景1.png");
        background-size: cover;
    }
}

.btn-verify {
  margin-left: 10rem;
  width: 108rem;
  height: 45rem;
  background: #f93086;
  border-radius: 22.5rem;
  font-family: "GenSenMaruGothicTW-Bold";
  font-size: 16rem;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 30rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>